var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AuthSlot',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Please log in and start the adventure ! ")]},proxy:true},{key:"form",fn:function(){return [_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',{staticClass:"my-1 justify-content-center"},[_c('b-col',{attrs:{"lg":"10","xl":"10"}},[_c('b-form',{staticClass:"auth-login-form",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{staticClass:"label-login",attrs:{"for":"login-email"}},[_vm._v("Email")])]),_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',[_c('div',{staticClass:"input-group-text"},[_c('feather-icon',{attrs:{"icon":"MailIcon","size":"18"}})],1)]),_c('b-form-input',{attrs:{"id":"login-email","size":"lg","state":errors.length > 0 ? false : null,"name":"login-email","placeholder":"john@example.com","autocomplete":"username"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{staticClass:"label-login",attrs:{"for":"login-password"}},[_vm._v("Password")]),_c('div',{staticClass:"d-flex justify-content-between",staticStyle:{"margin":"0.5rem 0"}},[_c('b-link',{attrs:{"to":{ name: 'forgot-password' }}},[_c('small',{class:_vm.theme === 'dark' ? 'text-white' : 'text-dark'},[_vm._v("Forgot Password?")])])],1)]),_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-input-group-prepend',[_c('div',{staticClass:"input-group-text"},[_c('feather-icon',{attrs:{"icon":"LockIcon","size":"18"}})],1)]),_c('b-form-input',{attrs:{"id":"login-password","size":"lg","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"Password","autocomplete":"current-password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('validation-provider',{attrs:{"name":"captcha","vid":"captcha","rules":"required|min:6|max:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{staticClass:"label-login",attrs:{"for":"login-captcha"}},[_vm._v("Captcha")])]),_c('b-row',{staticClass:"justify-content-between justify-content-md-center justify-content-sm-center"},[_c('b-col',{attrs:{"cols":_vm.colsInput}},[_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : '',attrs:{"size":"lg","name":"login-captcha","placeholder":"Captcha","maxlength":"6","minlength":"6"},model:{value:(_vm.captcha.text),callback:function ($$v) {_vm.$set(_vm.captcha, "text", $$v)},expression:"captcha.text"}})],1),_c('b-col',{class:_vm.hasMT ? 'has-mt' : '',attrs:{"cols":_vm.colsCaptcha}},[_c('b-input-group',{staticClass:"border rounded",staticStyle:{"min-width":"250px"}},[_c('b-form-input',{staticClass:"captcha-input",class:[_vm.theme === 'dark' ? 'captcha-dark' : ''],attrs:{"size":"lg","disabled":"","id":"captcha-img"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.refreshCaptcha}},[_c('feather-icon',{attrs:{"icon":"RefreshCwIcon","size":"18"}})],1)],1)],1)],1)],1)],1)]}}],null,true)}),(!_vm.loading)?_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid,"name":"btn-submit"}},[_vm._v(" Sign in ")])],1)],1):_c('b-button',{attrs:{"variant":"primary","disabled":"","block":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Loading... ")],1)],1)],1)],1)]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }