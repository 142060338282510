import { amgApi } from "@/service/axios";

class CaptchaService {
  async generate(body) {
    try {
      const data = await amgApi.get("/createCaptcha");
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default new CaptchaService();

