<template>
  <AuthSlot>
    <template #title> Please log in and start the adventure ! </template>
    <template #form>
      <validation-observer ref="loginForm" #default="{ invalid }">
        <b-row class="my-1 justify-content-center">
          <b-col lg="10" xl="10">
            <b-form class="auth-login-form" @submit.prevent="login">
              <b-form-group>
                <div class="d-flex justify-content-between text-white">
                  <label for="login-email" class="label-login">Email</label>
                </div>

                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-input-group-prepend>
                      <div class="input-group-text">
                        <feather-icon icon="MailIcon" size="18"></feather-icon>
                      </div>
                    </b-input-group-prepend>

                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      size="lg"
                      :state="errors.length > 0 ? false : null"
                      name="login-email"
                      placeholder="john@example.com"
                      autocomplete="username"
                    />
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <div class="d-flex justify-content-between text-white">
                    <label for="login-password" class="label-login"
                      >Password</label
                    >

                    <div
                      class="d-flex justify-content-between"
                      style="margin: 0.5rem 0"
                    >
                      <b-link :to="{ name: 'forgot-password' }">
                        <small
                          :class="theme === 'dark' ? 'text-white' : 'text-dark'"
                          >Forgot Password?</small
                        >
                      </b-link>
                    </div>
                  </div>

                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-input-group-prepend>
                      <div class="input-group-text">
                        <feather-icon icon="LockIcon" size="18"></feather-icon>
                      </div>
                    </b-input-group-prepend>

                    <b-form-input
                      id="login-password"
                      size="lg"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                      autocomplete="current-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <validation-provider
                #default="{ errors }"
                name="captcha"
                vid="captcha"
                rules="required|min:6|max:6"
              >
                <b-form-group>
                  <div class="d-flex justify-content-between text-white">
                    <label for="login-captcha" class="label-login"
                      >Captcha</label
                    >
                  </div>

                  <b-row
                    class="justify-content-between justify-content-md-center justify-content-sm-center"
                  >
                    <b-col :cols="colsInput">
                      <b-form-input
                        :class="errors.length > 0 ? 'is-invalid' : ''"
                        v-model="captcha.text"
                        size="lg"
                        name="login-captcha"
                        placeholder="Captcha"
                        maxlength="6"
                        minlength="6"
                      />
                    </b-col>
                    <b-col :cols="colsCaptcha" :class="hasMT ? 'has-mt' : ''">
                      <b-input-group
                        class="border rounded"
                        style="min-width: 250px"
                      >
                        <b-form-input
                          class="captcha-input"
                          size="lg"
                          disabled
                          id="captcha-img"
                          :class="[theme === 'dark' ? 'captcha-dark' : '']"
                        >
                        </b-form-input>
                        <b-input-group-append>
                          <b-button variant="primary" @click="refreshCaptcha">
                            <feather-icon
                              icon="RefreshCwIcon"
                              size="18"
                            ></feather-icon
                          ></b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </validation-provider>

              <b-row v-if="!loading" class="justify-content-center">
                <b-col cols="12">
                  <b-button
                    type="submit"
                    variant="primary"
                    block
                    :disabled="invalid"
                    name="btn-submit"
                  >
                    Sign in
                  </b-button>
                </b-col>
              </b-row>

              <b-button v-else variant="primary" disabled block>
                <b-spinner small />
                Loading...
              </b-button>
            </b-form>
          </b-col>
        </b-row>
      </validation-observer>
    </template>
  </AuthSlot>
</template>

<script>
/* eslint-disable global-require */
import { subscribeAllSocket } from "@/socket/index.js";
import AuthSlot from "@/views/pages/authentication/AuthSlot.vue";

import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CaptchaService from "@/service/captcha.js";

export default {
  components: {
    AuthSlot,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      captcha: {
        sensitive: "",
        img: "",
        key: "",
        text: "",
      },
      loadingPage: true,
      status: "",
      password: "",
      userEmail: "",
      required,
      email,
      rememberMe: null,
      loading: false,
      colsCaptcha: 6,
      colsInput: 6,
      hasMT: false,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters["app/currentBreakPoint"];
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    theme() {
      return store.state.appConfig.layout.skin === "dark" ? "dark" : "light";
    },
  },

  watch: {
    screenWidth() {
      if (this.screenWidth >= 1880) {
        this.colsCaptcha = 6;
        this.colsInput = 6;
        this.hasMT = false;
      } else if (this.screenWidth < 1880 && this.screenWidth >= 1650) {
        this.colsCaptcha = 7;
        this.colsInput = 5;
        this.hasMT = false;
      } else {
        this.colsCaptcha = 12;
        this.colsInput = 12;
        this.hasMT = true;
      }
    },
  },
  async created() {
    if (this.screenWidth >= 1880) {
      this.colsCaptcha = 6;
      this.colsInput = 6;
      this.hasMT = false;
    } else if (this.screenWidth < 1880 && this.screenWidth >= 1650) {
      this.colsCaptcha = 7;
      this.colsInput = 5;
      this.hasMT = false;
    } else {
      this.colsCaptcha = 12;
      this.colsInput = 12;
      this.hasMT = true;
    }

    await this.generateCaptcha();
    if (
      this.$route.query.email &&
      this.$route.query.password &&
      this.$route.query.module
    ) {
      this.userEmail = this.$route.query.email;
      this.password = this.$route.query.password;
      await this.loginUser(this.$route.query.module);
    }
  },
  mounted() {
    this.removePreloader();
  },

  methods: {
    async refreshCaptcha() {
      await this.generateCaptcha();
    },

    async generateCaptcha() {
      const { data } = await CaptchaService.generate();
      this.captcha.key = data.key;
      this.captcha.sensitive = data.sensitive;
      this.captcha.text = "";
      document.getElementById(
        "captcha-img"
      ).style.backgroundImage = `url(${data.img})`;
    },

    getToken(access_token, remember_token) {
      if (
        remember_token === null ||
        remember_token === undefined ||
        remember_token === ""
      ) {
        return access_token;
      } else {
        return remember_token;
      }
    },
    async loginUser(module = "") {
      this.loading = true;
      try {
        const response = await useJwt.login({
          email: this.userEmail,
          password: this.password,
          captcha_key: this.captcha.key,
          captcha_text: this.captcha.text,
          rememberMe: this.rememberMe,
        });

        if (!response) {
          this.loading = false;
          return;
        }

        if (this.isResponseSuccess(response)) {
          const userData = response.data.user;
          // if remember me is active use that token and set into access token else use bearer_token from database
          const userToken = this.getToken(
            response.data.access_token,
            response.data.remember
          );
          useJwt.setToken(userToken);
          // useJwt.setRefreshToken(response.data.refreshToken)
          localStorage.setItem("userData", JSON.stringify(userData));
          localStorage.setItem("lateAttendanceNotificationsUpdated", "false");
          this.$ability.update(userData.ability);
          await this.$store.dispatch("auth/updateCurrentUser", userData);
          await this.$store.dispatch("auth/updateToken", userToken);
          subscribeAllSocket();
          if (module) userData.module = module;
          this.$router
            .replace(getHomeRouteForLoggedInUser(userData))
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Welcome ${userData.fullName}`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `You have successfully logged in as ${userData.roleName}. Now you can start to explore!`,
                },
              });
            });
        }
      } catch (error) {
        await this.generateCaptcha();

        this.loadingPage = false;
        this.status = error.response.status;
        if (error.response?.data.error == "expired_password") {
          this.$router.push({
            name: "expired-password",
            params: {
              email: error.response?.data.email,
              correct_password: error.response?.data.correct_password,
            },
          });
        }
        if (this.status === 401) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "warning",
              text: error.response.data.error,
            },
          });
        } else {
          this.$refs.loginForm.setErrors(error.response.data.error);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: error.response.data.error,
            },
          });
        }
      } finally {
        this.loading = false;
      }
    },
    async login() {
      const response = await this.$refs.loginForm.validate();
      if (response) {
        await this.loginUser();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#captcha-img {
  background-repeat: no-repeat;
  padding: 10px;
  background-position: center;
  background-size: object-fit;
}

.has-mt {
  margin-top: 20px;
}
</style>
